import React from "react"
import Layout from "../components/layout"
import { PageProps } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"
// @ts-ignore
import DesignGallery from "../components/SwiperSlider/DesignGallery"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
// @ts-ignore
import Fade from "react-reveal/Fade"

interface IAboutProps extends PageProps {
  className?: string
}

const Design: React.FC<IAboutProps> = ({ className,location:{pathname:path}, data }) => {
  // @ts-ignore
  return (
    <Layout lang="en" page={path}>
      <SEO title="Through Fotografika, Misha Kojic with his unique blend of minimalistic photography illuminates the unseen."
           description="Casa Italia's workshop allows artists to showcase their talent and inspire others"
           keywords="Italian furniture, Luxury furniture, Design resource, Fine furniture, Design solutions,
Contemporary design"
      />
      <div className={className}>
        <Fade top>
          <PageTitle>
            Design <SpanDivider fontSize="calc(30px + (45 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Studio
          </PageTitle>
        </Fade>

        <section className="section design-page">
          <div className="design-page__top-content">
            <h2 className="design-page__inner-title">FotoGrafika</h2>
            <span className="design-page__subtitle">Misha Kojic / Photography</span>
            <p>Often times it is difficult to fully digest the beauty of subtlety. Minor miracles occur every day before
              our eyes yet they go unnoticed in a world of chaos and static.
              Misha’s art is aimed at the isolation of those miracles. In unadulterated space, their remarkable shape,
              color, texture and brilliance is exposed.
              Through FotoGrafika, his unique blend of minimalistic photography and graphic design, he illuminates the
              unseen.
              Here are some of Misha’s amazing images. If you would like to see a full gallery of his work, we invite
              you to visit the Fotografika website.</p>
            <a target="blank" href="http://fotografikaonline.com/artist.asp?ArtistID=24694&Akey=7T5M8Y3K&flM=1"
               className="btn forshowcase">Visit Site</a>
          </div>
          <div className="design-content">
            <DesignGallery />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default styled(Design)`
  color:black;top: 0;
  padding-top: 25px;
  position:relative;
  .design-page {
    width:100%;
    @media screen and (min-width:900px) {
      width:85%;
    }
    max-width:1200px;
    margin:0 auto 50px auto;
    padding:0;
    background-color:#e9e9e9;
    line-height: 27px;
    font-size:16px;
    &__top-content {
      padding:30px;
      position: relative;
      p {
        font-family: "California FB Regular",serif;
        @media screen and (min-width:900px){
          padding:0 35px;
        }
      }
    }
    &__inner-title {
      color: red;
      text-transform: uppercase;
      font-weight: 200;
      font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (900 - 300)));
      margin-bottom:10px;
      letter-spacing: 10px;
    }
    &__subtitle {
      color: red;
      text-transform: uppercase;
      display: block;
      margin-bottom:50px;
      font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (900 - 300)));
      letter-spacing: 2px;
    }
  }
  .design-content {
    background:#DDDDDD;
    @media screen and (min-width:768px) {
      padding:30px
    }
  }
`
