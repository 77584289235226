import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage  from "gatsby-image"
import { NavNextButton, NavPrevtButton } from "./SliderNavigationButtons"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import SwiperCore, { Navigation , Autoplay, Thumbs, EffectFade } from 'swiper/core';

SwiperCore.use([Navigation, Autoplay, Thumbs, EffectFade]);
const DesignGallery = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const data = useStaticQuery(graphql`
    query FotografikaSliderImages {
      placeholderImages: allFile(filter: {relativeDirectory: {eq: "fotografika"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const fluidImages = data.placeholderImages.nodes.map(pi => {
    return pi.childImageSharp.fluid
  })
  const gallerySwiperParams = {
    style: {height: "100%"},
    navigation: {
      nextEl: '.design-btn-next',
      prevEl: '.design-btn-prev',
    },
    effect: 'fade'
  };
  const thumbSwiperParams = {
    style: {
      marginTop: "20px"
    },
    spaceBetween: 10,
    centeredSlides: false,
    slidesPerView: 8,
    touchRatio: 0.2,
    slideToClickedSlide: true
  }
  return (
    <div>
      <Swiper {...gallerySwiperParams} thumbs={{ swiper: thumbsSwiper }}>
        { fluidImages.map((fi, index) => {
          return <SwiperSlide key={`slideContent-${index}`}
          >
            <GatsbyImage fluid={fi} />
          </SwiperSlide>
        })}
        <NavPrevtButton addclass="design-btn-prev" />
        <NavNextButton addclass="design-btn-next" />
      </Swiper>
      <Swiper { ...thumbSwiperParams } onSwiper={setThumbsSwiper}>
        { fluidImages.map((fi, index) => {
          return <SwiperSlide key={`slideContent-${index}`}
          >
            <GatsbyImage fluid={fi} />
          </SwiperSlide>
        })}
      </Swiper>
    </div>
  );
};
export default DesignGallery;
